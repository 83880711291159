import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const MissionStatementPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Mission Statement" />
      <article>
        <h1>Mission Statement</h1>
        <p>The Australian Journal of Anarchist Theory is a collaborative publication of Anarchist-Communist organisations in Australia. The journal aims to provide analysis of theory, history and strategy informed by past and current struggle. It seeks to be relevant to both Australian and global contexts. The analysis we present is informed by the principles of Anarchist-Communism.</p>
        <p>Anarchist-Communism aims for a socialist and libertarian society in which resources are distributed from each according to their ability, to each according to their need. Work will be self-managed and coordinated through free and democratic association. To achieve this, the working class must organise itself such that it is capable of seizing power from the capitalist class and from the state and begin restructuring society. This must be achieved by the efforts of the working class itself, and not a body that purports to act on its behalf.</p>
        <p>If we are to see our ideal society realised, the ideas of Anarchist-Communism must be the leading ideas of the working class. In producing analysis and theory through this journal, we aim to prove the relevance and clarify the ideas of Anarchist-Communism. Through the demonstration of our ideas, combined with our deeds, we seek to spread Anarchist-Communism amongst the working class.</p>
        <p>The journal, by contributing to debates both within the Anarchist-Communist milieu, and the revolutionary movement generally, will also help refine Anarchist-Communist theory in Oceania.</p>
        <p>The Australian Journal of Anarchist Theory is published by the following Anarchist-Communist Organisations:</p>
        <p>
          Anarchist Communists Meanjin<br />
          Black Flag Sydney<br />
          Geelong Anarchist Communists
        </p>
      </article>
    </Layout>
  )
}

export default MissionStatementPage

export const pageQuery = graphql`
query {
site {
siteMetadata {
title
}
}
}
`
